<template>
	<w-btn :color="color" :disabled="disabled" :flat="flat" icon="save" :loading="loading" :mini="mini" :small="small" v-on="$listeners">
		{{ text || $t('actions.save') }}
	</w-btn>
</template>

<script>
import ButtonParentClass from '@/components/Commons/Buttons/ButtonParentClass'

/**
 * @displayName w-btn-save
 *
 * @since 0.3.2
 */
export default {
	name: 'WBtnSave',
	extends: ButtonParentClass
}
</script>
